<script lang="js">
import NavBarItem from './NavBarItem.vue';
import Search from './Search.vue';

export default {
  components: {
    NavBarItem,
    Search
  },
  data() {
    return {
      navItems: [
        { name: 'Académico', path: 'academico', dropdownItems: ['Licenciatura', 'Maestría', 'Doctorado', 'Estudia en Línea', 'Educación Continua'],showDropdown: false },
        { name: 'Aprende', path: 'aprende', dropdownItems: ['Cultura General', 'Tendencias', 'Tips'],showDropdown: false },
        // { name: 'Recursos', path: 'recursos', dropdownItems: ['Casos Reales', 'Ebooks', 'Entrevistas y Conferencias', 'Podcast', 'Webinars'] },
        { name: 'Recursos', path: 'recursos', dropdownItems: ['Casos Reales', 'Entrevistas y Conferencias'],showDropdown: false },
        // { name: 'Ser Humanitas', path: 'ser-humanitas', dropdownItems: ['Actividades en Campus', 'Casos de éxito', 'Responsabilidad Social', 'Testimonios', 'Comunidad'] },
        { name: 'Ser Humanitas', path: 'ser-humanitas', dropdownItems: [ 'Responsabilidad Social', 'Comunidad' ],showDropdown: false },
      ],
      isMenuOpen: false,
      showDropdown: false
    };
  },
  methods: {
    // @ts-ignore
    closeOthers(isOpen) {
      if (isOpen) {
        this.navItems.forEach(item => {
          // @ts-ignore
          if (item.isOpen && item !== this) {
            // @ts-ignore
            item.isOpen = false;
          }
        });
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    toggleDrop() {
      this.showDropdown = !this.showDropdown;
    },
    // @ts-ignore
    toggleDropdown(item) {
      item.showDropdown = !item.showDropdown;
    },
    toggleSearch() {
        // @ts-ignore
        this.showSearch = !this.showSearch;
        // @ts-ignore
        if (this.showSearch) {
          this.$nextTick(() => {
            // @ts-ignore
            this.$refs.searchInput.focus();
          });
        }
    },
    search() {
      // Aquí puedes implementar la lógica para realizar la búsqueda
      // @ts-ignore
      console.log('Buscando:', this.searchQuery);
      // Por ejemplo, puedes emitir un evento para notificar al componente padre de la consulta de búsqueda
      // @ts-ignore
      this.$emit('search', this.searchQuery);
    },
    // @ts-ignore
    redirectToLink(item) {
      // Redirige al enlace del router
      // @ts-ignore
      this.$router.push(`/${item}`);
      this.isMenuOpen = false
    },
    // @ts-ignore
    handleMouseDown(item) {
      // @ts-ignore
      this.timer = setTimeout(() => {
        this.redirectToLink(item);
      }, 1000); // Tiempo de espera en milisegundos (aquí 1000 ms = 1 segundo)
    },

    handleMouseUp() {
      // @ts-ignore
      clearTimeout(this.timer);
    },

    handleMouseLeave() {
      // @ts-ignore
      clearTimeout(this.timer);
    }
  }
};
</script>

<style>
/* Estilos específicos según tus necesidades */
</style>

<template>
  <div class="flex flex-col bg-white items-center justify-center w-full z-50 font-sans text-black fixed top-0 left-0">
    <nav class=" flex justify-center items-center lg:max-w-[1200px] px-2 bg-white max-w-6xl z-50 w-full">
      <div class=" w-full bg-white flex flex-col max-w-6xl lg:max-w-[1200px]  justify-between z-50">
        <div class="flex justify-start max-w-6xl lg:max-w-[1500px] text-[14px]">
          <router-link to="/" class="text-[#881912] mt-4 hover:text-[#c4a96c] text-left cursor-pointer hidden md:block ">Ir al Sitio Principal</router-link>
        </div>
        <div class="flex max-w-6xl lg:max-w-[1500px] w-full items-center justify-between ">
          <!-- Logo -->
          <div class="flex items-center justify-center w-full max-w-[25%]">
            <router-link to="/blog">
              <img src="../pages/images/Logotipo-Universidad-Humanitas-dorado-2024.png" class="hidden lg:block">
              <img src="../pages/images/logo_H_mobile.png" class="lg:hidden px-2 pt-2 w-[70px]">
            </router-link>
          </div>
          <!-- <div class="flex items-center justify-center">
            <div class="md:hidden px-2">
                <p class=" text-3xl font-medium text-[#881912]">BLOG</p>
            </div>
          </div> -->
          <!-- Navbar items -->
          <div class="flex relative justify-center">
            <div class="hidden md:block">
              <div class=" flex items-center w-full">
                <div class="flex justify-between lg:space-x-4 space-x-2">
                  <!-- Elementos principales -->
                  <NavBarItem v-for="(item, index) in navItems" :key="index" :name="item.name" :path="item.path" :dropdownItems="item.dropdownItems" @close-others="closeOthers"/>
                  <Search class="hidden md:block"/>
                </div>
              </div>
            </div>
            <div class="md:hidden select-none bg-white w-full max-w-4xl lg:max-w-[1200px]" style="font-family: 'Helvetica Neue Regular', sans-serif;">
              <button @click="toggleMenu" class="bg-white focus:outline-none ">
                <img v-if="!isMenuOpen" src="../pages/images/menu-movil.png" alt="Abrir menú" class="h-8 bg-white"/>
                <img v-else src="../pages/images/tache.png" alt="cerrar menú" class="h-8 bg-white"/>
              </button>
              <div id="menu" :class="{ 'block': isMenuOpen, 'hidden': !isMenuOpen }" class="lg:flex absolute bg-white lg:items-center lg:space-x-4 right-0 mt-0 z-50 font-montse border border-y-[#CAAB55] lg:border-none">
                <ul class="flex flex-col lg:flex-row lg:justify-between lg:text-xs w-full divide-y divide-[#CAAB55]/90 lg:divide-y-0">
                  <li v-for="(item, index) in navItems" :key="index" class="relative cursor-pointer group content-center" @mousedown="handleMouseDown(item.path)" @mouseup="handleMouseUp" @mouseleave="handleMouseLeave">
                    <p class="flex items-center justify-center hover:text-[#CAAB55] py-3 lg:py-0 h-full hover:border-b hover:border-dashed border-gray-400/90 lg:hover:border-b-0"
                      @click="toggleDropdown(item)" >
                      <span class="text-[20px] mx-32 whitespace-nowrap">{{ item.name }}</span>
                    </p>
                    <ul v-if="item.showDropdown" class="left-0 bg-white border border-white bg-opacity-80 text-[17px]">
                      <li v-for="dropdownItem in item.dropdownItems" :key="dropdownItem" @click="toggleMenu"  class="hover:bg-gray-200 text-gray-700 border-b border-t border-dashed border-gray-400/90">
                        <router-link :to="`/blog/${item.path}/${dropdownItem.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/ /g, '-')}`">
                          <p class="my-2 text-gray-700 hover:text-hone">{{ dropdownItem }}</p>
                        </router-link>
                      </li>
                    </ul>
                  </li>
                  <!-- <input type="text"  placeholder="Buscar..." @keydown.enter="search" class="border-t-[#CAAB55] lg:border-none" style="width: 100%;padding: 10px 10px; font-size: 16px;color: black;margin-bottom: 8px;border: none;border-top: 1px solid #CAAB55;border-bottom: 1px solid #797979;outline: none;background: transparent;"> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="flex justify-center items-center text-center w-full relative">
            <!-- Primera mitad de la línea -->
            <div class="flex-grow">
                <div class="h-3 border-b-[1px] border-[#797979]"></div>
                <div class="h-3 "></div>
            </div>

            <div class="flex items-center sm:justify-between text-center w-full max-w-6xl lg:max-w-[1200px]">
              <div class="lg:w-full md:w-full w-[50%] md:hidden">
                  <div class="h-3 border-b-[1px] border-[#797979]"></div>
                  <div class="h-3"></div>
              </div>
              <div class="w-fit px-3">
                  <p class=" text-[18px] font-medium text-[#881912]">BLOG</p>
              </div>
              <!-- Segunda mitad de la línea -->
              <div class="lg:w-full md:w-full w-[50%] ">
                  <div class="h-3 border-b-[1px] border-[#797979]"></div>
                  <div class="h-3"></div>
              </div>
            </div>

            <div class="flex-grow">
                <div class="h-3 border-b-[1px] border-[#797979]">&nbsp;</div>
                <div class="h-3">&nbsp;</div>
            </div>
		</div>
  </div>
</template>
<style>
    /* Estilo personalizado para el nombre */
    .navbar-name {
    font-family: 'Helvetica Neue Regular', sans-serif;
    font-size: 14px;
    }

    /* Estilo personalizado para las opciones del dropdown */
    .dropdown-item {
    font-family: 'Helvetica Neue Italic', sans-serif;
    font-size: 14px;
    }

    /* Estilos para hover sobre las opciones del dropdown */
    .dropdown-item:hover {
    background-color: #881912;
    color: white;
    }
</style>