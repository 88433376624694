export default [
    {
      path: '/blog/ser-humanitas/responsabilidad-social/ejemplos-responsabilidad-social-tipos-e-importancia',
      name: 'Ejemplos de responsabilidad social: Tipos y su importancia para la sociedad',
      meta: {
        title: "Ejemplos de responsabilidad social: Tipos y su importancia para la sociedad",
        keywords: "ejemplos de responsabilidad, prácticas de RSC y ESG, responsabilidad social individual, que es una responsabilidad, definición de responsabilidad, 3 ejemplos de responsabilidad, tipos de RSC por los que puedes apostar, ventajas de realizar acciones de responsabilidad social corporativa en tu empresa, ¿por qué son necesarias las prácticas de RSC y ESG en una  empresa?, ventajas de implementar la responsabilidad social corporativa",
        metaTags:
        {
          "og:keywords": "ejemplos de responsabilidad, prácticas de RSC y ESG, responsabilidad social individual, que es una responsabilidad, definición de responsabilidad, 3 ejemplos de responsabilidad, tipos de RSC por los que puedes apostar, ventajas de realizar acciones de responsabilidad social corporativa en tu empresa, ¿por qué son necesarias las prácticas de RSC y ESG en una  empresa?, ventajas de implementar la responsabilidad social corporativa"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/RespoSocial/0087-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-111024.vue')
    },
    {
      path: '/blog/ser-humanitas/responsabilidad-social/responsabilidad-social-individual-tipos-ejemplos-inspiracion',
      name: 'Responsabilidad Social Individual: Descubre qué es e inspírate',
      meta: {
        title: "Responsabilidad Social Individual: Descubre qué es e inspírate",
        description: "Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.",
        keywords: "Responsabilidad social individual, ejemplos de responsabilidad, tipos de responsabilidad social, responsabilidad social personal o individual, 3 ejemplos de responsabilidad, ejemplos de responsabilidad social individual",
        metaTags:
        {
          "og:title": "Responsabilidad Social Individual: Descubre qué es e inspírate",
          "og:description": "Explora qué es la Responsabilidad Social Individual, qué  tipos existen y ejemplos. Aprende cómo puedes marcar la diferencia en tu comunidad y entorno.",
          "og:keywords": "responsabilidad social, responsabilidad social, universitaria, universidad"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/RespoSocial/0034-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-030624.vue')
    },
    {
        path: '/blog/ser-humanitas/responsabilidad-social/por-que-es-importante-la-responsabilidad-social-universitaria',
        name: '¿Por qué es importante la Responsabilidad Social Universitaria? Entérate',
        meta: {
          title: "¿Por qué es importante la Responsabilidad Social Universitaria? Entérate",
          description: "Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.",
          keywords: "responsabilidad social, responsabilidad social, universitaria, universidad",
          metaTags:
          {
            "og:title": "¿Por qué es importante la Responsabilidad Social Universitaria? Entérate",
            "og:description": "Aprende cómo la Responsabilidad Social Universitaria impulsa el cambio social, la sostenibilidad y también el desarrollo de tus habilidades profesionales.",
            "og:keywords": "responsabilidad social, responsabilidad social, universitaria, universidad"
          },
          requireAuth: false,
        },
        component: () => import('../../pages/Blog/SerHuma/RespoSocial/12-ART-SERHUMANITAS-RESPSOCIAL-130324.vue')
    },
    {
      path: '/blog/ser-humanitas/responsabilidad-social/acciones-socialmente-responsables',
      name: '¿Qué es una acción socialmente responsable?',
      meta: {
        title: "¿Qué es una acción socialmente responsable?",
        description: "Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.",
        keywords: "Responsabilidad ejemplos, requisitos para ser una empresa socialmente responsable, responsabilidad social con los clientes ejemplos, ejemplos de responsabilidad social y actividades de responsabilidad social.",
        metaTags:
        {
          "og:title": "¿Qué es una acción socialmente responsable?",
          "og:description": "Para saber qué es una acción socialmente responsable, recurrimos a los estándares internacionales y a ejemplos concretos.",
          "og:keywords": "Responsabilidad ejemplos, requisitos para ser una empresa socialmente responsable, responsabilidad social con los clientes ejemplos, ejemplos de responsabilidad social y actividades de responsabilidad social.",
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/RespoSocial/0024-ART-SER-HUMANITAS-RESPONSABILIDAD-SOCIAL-090524.vue')
    },
]