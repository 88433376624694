export default [
    {
      path: '/blog/ser-humanitas/comunidad/mejores-universidades-privadas-mexico-opciones',
      name: '¿Cuáles son las mejores universidades privadas? ',
      meta: {
        title: "¿Cuáles son las mejores universidades privadas?",
        keywords: "¿cuáles son las mejores universidades privadas?",
        metaTags:
        {
          "og:keywords": "¿cuáles son las mejores universidades privadas?"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/Comunidad/0085-ART-SER-HUMANITAS-COMUNIDAD-091024.vue')
    },
    {
      path: '/blog/ser-humanitas/comunidad/universidades-privadas-en-queretaro',
      name: 'Universidades privadas en Querétaro: guía completa',
      meta: {
        title: "Universidades privadas en Querétaro: guía completa",
        keywords: "Universidades privadas en Querétaro, Universidades sabatinas en Querétaro, Universidades públicas en Querétaro",
        metaTags:
        {
          "og:keywords": "Universidades privadas en Querétaro, Universidades sabatinas en Querétaro, Universidades públicas en Querétaro"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/Comunidad/0081-ART-SER-HUMANITAS-COMUNIDAD-021024.vue')
    },
    {
      path: '/blog/ser-humanitas/comunidad/universidad-humanitas-campus-queretaro',
      name: 'Universidad Humanitas campus Querétaro: Educación de alto nivel',
      meta: {
        title: "Universidad Humanitas campus Querétaro: Educación de alto nivel",
        keywords: "Universidad Humanitas campus Querétaro",
        metaTags:
        {
          "og:keywords": "Universidad Humanitas campus Querétaro"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/Comunidad/0065-ART-SER-HUMANITAS-COMUNIDAD-030924.vue')
    },
    {
      path: '/blog/ser-humanitas/comunidad/campus-presa-madin',
      name: 'Universidad Humanitas campus Presa Madín: conócela',
      meta: {
        title: "Universidad Humanitas campus Presa Madín: conócela",
        keywords: "¿Qué carreras hay en la Universidad Humanitas campus Presa Madín?, ¿Qué se necesita para entrar a la Universidad Humanitas campus Presa Madín?, Universidad Humanitas campus Presa Madín: cómo llegar, Presa Madín, Atizapán de Zaragoza: breve historia",
        metaTags:
        {
          "og:keywords": "¿Qué carreras hay en la Universidad Humanitas campus Presa Madín?, ¿Qué se necesita para entrar a la Universidad Humanitas campus Presa Madín?, Universidad Humanitas campus Presa Madín: cómo llegar, Presa Madín, Atizapán de Zaragoza: breve historia"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/Comunidad/0060-ART-SER-HUMANITAS-COMUNIDAD-260824.vue')
    },
    {
      path: '/blog/ser-humanitas/comunidad/universidad-humanitas-tijuana-descubre-tu-potencial',
      name: 'Universidad Humanitas Campus Magno Tijuana: Descubre tu potencial aquí',
      meta: {
        title: "Universidad Humanitas Campus Magno Tijuana: Descubre tu potencial aquí",
        keywords: "universidad humanitas carreras, humanitas tijuana carreras, cuanto cuesta estudiar en humanitas, universidad humanitas campus magno tijuana, humanitas rvoe, universidad humanitas modalidades, universidad humanitas colegiaturas, conoce la universidad humanitas tijuana",
        metaTags:
        {
          "og:keywords": "universidad humanitas carreras, humanitas tijuana carreras, cuanto cuesta estudiar en humanitas, universidad humanitas campus magno tijuana, humanitas rvoe, universidad humanitas modalidades, universidad humanitas colegiaturas, conoce la universidad humanitas tijuana"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/Comunidad/0059-ART-SER-HUMANITAS-COMUNIDAD-230824.vue')
    },
    {
      path: '/blog/ser-humanitas/comunidad/cuanto-cuesta-mensualidad-universidad-humanitas',
      name: '¿Cuánto cuesta una mensualidad en la Universidad Humanitas?',
      meta: {
        title: "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
        description: "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
        image: "https://blog.humanitas.edu.mx/images/meta/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
        keywords: "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?, Universidad Humanitas precios, modelo escolarizado, modelo ejecutivo, modelo virtual",
        metaTags:
        {
          "og:title": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?",
          "og:description": "Descubre cuánto cuesta una mensualidad en la Universidad Humanitas y el nivel de la calidad educativa de reconocimiento internacional.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/cuanto-cuesta-mensualidad-universidad-humanitas-instalaciones.webp",
          "og:keywords": "¿Cuánto cuesta una mensualidad en la Universidad Humanitas?, Universidad Humanitas precios, modelo escolarizado, modelo ejecutivo, modelo virtual"
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/SerHuma/Comunidad/0035-ART-SER-HUMANITAS-COMUNIDAD-300524.vue')
    },
]