import doctoradoRoutes from "./Academico/doctoradoRoutes.ts";
import educaConRoutes from "./Academico/educaConRoutes.ts";
import estudiaLinRoutes from "./Academico/estudiaLinRoutes.ts"
import licenciaturaRoutes from "./Academico/licenciaturaRoutes.ts";
import maestriaRoutes from "./Academico/maestriaRoutes.ts"


export default [
    {
        path: '/blog/academico/educacion-continua',
        name: 'Académico: Educación Continua | Blog Humanitas',
        component: () => import('../pages/Blog/Academico/Academico-Continua.vue'),
        meta: {
            title: "Académico: Educación Continua | Blog Humanitas",
            requireAuth: false
        },
    },
    ...educaConRoutes,
    {
        path: '/blog/academico/doctorado',
        name: 'Académico: Doctorado | Blog Humanitas',
        component: () => import('../pages/Blog/Academico/Academico-Doctorado.vue'),
        meta: {
            title: "Académico: Doctorado | Blog Humanitas",
            requireAuth: false
        },
    },
    ...doctoradoRoutes,
    {
        path: '/blog/academico/estudia-en-linea',
        name: 'Académico: Estudia en Línea | Blog Humanitas',
        component: () => import('../pages/Blog/Academico/Academico-EstudiaLin.vue'),
        meta: {
            title: "Académico: Estudia en Línea | Blog Humanitas",
            requireAuth: false
        },
    },
    ...estudiaLinRoutes,
    {
        path: '/blog/academico/licenciatura',
        name: 'Académico: Licenciatura | Blog Humanitas',
        component: () => import('../pages/Blog/Academico/Academico-Licenciatura.vue'),
        meta: {
            title: "Académico: Licenciatura | Blog Humanitas",
            requireAuth: false
        },
    },
    ...licenciaturaRoutes,
    {
        path: '/blog/academico/maestria',
        name: 'Académico: Maestría | Blog Humanitas',
        component: () => import('../pages/Blog/Academico/Academico-Maestria.vue'),
        meta: {
            title: "Académico: Maestría | Blog Humanitas",
            requireAuth: false
        },
    },
    ...maestriaRoutes
]