export default [
  {
    path: '/blog/aprende/cultura-general/napoleon-bonaparte-cual-fue-su-emblema-personal',
    name: 'Napoleón Bonaparte: ¿cuál fue su emblema personal?',
    meta: {
      title: "Napoleón Bonaparte: ¿cuál fue su emblema personal?",
      keywords: "Napoleón emblema personal, Cuál era el símbolo de Napoleón, Por qué Napoleón eligió la abeja como símbolo, Qué significa la abeja de Napoleón, Qué representa Napoleón Bonaparte",
      metaTags:
      {
        "og:keywords": "Napoleón emblema personal, Cuál era el símbolo de Napoleón, Por qué Napoleón eligió la abeja como símbolo, Qué significa la abeja de Napoleón, Qué representa Napoleón Bonaparte"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0103-ART-APRENDE-CULTURA-GENERAL-081124.vue')
  },
  {
    path: '/blog/aprende/cultura-general/ayuno-intermitente-un-menu-distinto',
    name: 'El ayuno intermitente: un menú distinto y otra mirada a la nutrición',
    meta: {
      title: "El ayuno intermitente: un menú distinto y otra mirada a la nutrición",
      keywords: "El ayuno intermitente: un menú distinto y otra mirada a la nutrición, menú ayuno intermitente, qué es el ayuno intermitente, cómo funciona el ayuno intermitente, cómo hacer ayuno, cómo se hace el ayuno intermitente, dieta con ayuno intermitente",
      metaTags:
      {
        "og:keywords": "El ayuno intermitente: un menú distinto y otra mirada a la nutrición, menú ayuno intermitente, qué es el ayuno intermitente, cómo funciona el ayuno intermitente, cómo hacer ayuno, cómo se hace el ayuno intermitente, dieta con ayuno intermitente"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0100-ART-APRENDE-CULTURA-GENERAL-041124.vue')
  },
  {
    path: '/blog/aprende/cultura-general/que-es-la-dieta-keto',
    name: '¿Qué es la dieta Keto?: mitos y realidades',
    meta: {
      title: "¿Qué es la dieta Keto?: mitos y realidades",
      keywords: "¿Qué es la dieta Keto?: mitos y realidades, dieta cetogénica, como funciona una dieta cetogénica, como funciona la dieta Keto, que es cetosis",
      metaTags:
      {
        "og:keywords": "¿Qué es la dieta Keto?: mitos y realidades, dieta cetogénica, como funciona una dieta cetogénica, como funciona la dieta Keto, que es cetosis"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0101-ART-APRENDE-CULTURA-GENERAL-061124.vue')
  },
  {
    path: '/blog/aprende/cultura-general/alimentos-que-pueden-prevenir-enfermedades',
    name: 'Mejora tu salud a través de una alimentación saludable',
    meta: {
      title: "Mejora tu salud a través de una alimentación saludable",
      keywords: "Mejora tu salud a través de una alimentación saludable, alimentos que pueden prevenir enfermedades, ¿Qué alimentos principalmente nos protegen frente a las enfermedades?, qué son los alimentos naturales, alimentos procesados ejemplos, definición de alimentos procesados, qué son los alimentos procesados, alimentos mínimamente procesados, alimentos procesados",
      metaTags:
      {
        "og:keywords": "Mejora tu salud a través de una alimentación saludable, alimentos que pueden prevenir enfermedades, ¿Qué alimentos principalmente nos protegen frente a las enfermedades?, qué son los alimentos naturales, alimentos procesados ejemplos, definición de alimentos procesados, qué son los alimentos procesados, alimentos mínimamente procesados, alimentos procesados"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0097-ART-APRENDE-CULTURA-GENERAL-291024.vue')
  },
  {
    path: '/blog/aprende/cultura-general/la-vida-secreta-de-las-abejas-tipos-y-roles-en-la-colmena',
    name: 'El asombroso mundo de las abejas: Tipos, roles y razones para protegerlas',
    meta: {
      title: "El asombroso mundo de las abejas: Tipos, roles y razones para protegerlas",
      keywords: "tipos de abejas, promedio de vida de una abeja, informacion sobre las abejas, información sobre las abejas, abeja zángano, cuánto vive una abeja, como es la reina de las abejas",
      metaTags:
      {
        "og:keywords": "tipos de abejas, promedio de vida de una abeja, informacion sobre las abejas, información sobre las abejas, abeja zángano, cuánto vive una abeja, como es la reina de las abejas"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0098-ART-APRENDE-CULTURA-GENERAL-291024.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dieta-para-universitarios',
    name: 'Dieta para universitarios: mejora tu desempeño',
    meta: {
      title: "Dieta para universitarios: mejora tu desempeño",
      keywords: "dieta balanceada para estudiantes, dieta para bajar de peso para estudiantes, beneficios de una buena alimentación en estudiantes, dieta equilibrada para un estudiante",
      metaTags:
      {
        "og:keywords": "dieta balanceada para estudiantes, dieta para bajar de peso para estudiantes, beneficios de una buena alimentación en estudiantes, dieta equilibrada para un estudiante"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0092-ART-APRENDE-CULTURA-GENERAL-171024.vue')
  },
  {
    path: '/blog/aprende/cultura-general/5-libros-interesantes-para-jovenes-universitarios-que-debes-leer',
    name: '5 libros para jóvenes universitarios que debes leer',
    meta: {
      title: "5 libros para jóvenes universitarios que debes leer",
      keywords: "libros para jóvenes universitarios, libros interesantes, libro bueno, mejores libros para leer",
      metaTags:
      {
        "og:keywords": "libros para jóvenes universitarios, libros interesantes, libro bueno, mejores libros para leer"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0089-ART-APRENDE-CULTURA-GENERAL-151024.vue')
  },
  {
    path: '/blog/aprende/cultura-general/diferencia-entre-habilidades-y-competencias',
    name: 'Diferencia entre habilidades y competencias: forma tu carrera profesional',
    meta: {
      title: "Diferencia entre habilidades y competencias: forma tu carrera profesional",
      keywords: "Habilidades y competencias, diferencia entre talento y habilidad, diferencia entre habilidades y aptitudes, que son las habilidades y destrezas, diferencia entre cualidad y habilidad",
      metaTags:
      {
        "og:keywords": "Habilidades y competencias, diferencia entre talento y habilidad, diferencia entre habilidades y aptitudes, que son las habilidades y destrezas, diferencia entre cualidad y habilidad"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0086-ART-APRENDE-CULTURA-GENERAL-081024.vue')
  },
  {
    path: '/blog/aprende/cultura-general/para-que-sirve-el-liderazgo',
    name: '¿Para qué sirve el liderazgo?; Aquí su importancia y beneficios',
    meta: {
      title: "¿Para qué sirve el liderazgo?; Aquí su importancia y beneficios",
      keywords: "¿Para qué sirve el liderazgo?, ¿Para qué sirve el liderazgo?, término, concepto, características, tipos de liderazgo, cualidades de un líder",
      metaTags:
      {
        "og:keywords": "¿Para qué sirve el liderazgo?, ¿Para qué sirve el liderazgo?, término, concepto, características, tipos de liderazgo, cualidades de un líder"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0078-ART-APRENDE-CULTURA-GENERAL-270924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/porque-es-importante-la-inteligencia-emocional',
    name: '¿Por qué es importante la inteligencia emocional?',
    meta: {
      title: "¿Por qué es importante la inteligencia emocional?",
      keywords: "¿Por qué es importante la inteligencia emocional?,Inteligencia emocional: definición, significado, beneficios y ventajas. Inteligencia emocional: en el trabajo, en la escuela y en la familia",
      metaTags:
      {
        "og:keywords": "¿Por qué es importante la inteligencia emocional?,Inteligencia emocional: definición, significado, beneficios y ventajas. Inteligencia emocional: en el trabajo, en la escuela y en la familia"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0077-ART-APRENDE-CULTURA-GENERAL-240924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/nietzsche-para-generacion-z-filosofia',
    name: 'Nietzsche para la Generación Z: Filosofía para cuestionar todo lo que sabes',
    meta: {
      title: "Nietzsche para la Generación Z: Filosofía para cuestionar todo lo que sabes",
      keywords: "quien es nietzsche, nietzsche para jóvenes, corriente filosofica de nietzsche, nihilismo, nietzsche pensamiento, nietzsche filosofia, generación z",
      metaTags:
      {
        "og:keywords": "quien es nietzsche, nietzsche para jóvenes, corriente filosofica de nietzsche, nihilismo, nietzsche pensamiento, nietzsche filosofia, generación z"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0075-ART-APRENDE-CULTURA-GENERAL-190924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/peliculas-de-abogados',
    name: 'Películas para estudiantes y abogados imperdibles',
    meta: {
      title: "Películas para estudiantes y abogados imperdibles",
      keywords: "Películas de abogados, Películas de abogados en Netflix, series de abogados en Netflix, películas de Derecho",
      metaTags:
      {
        "og:keywords": "Películas de abogados, Películas de abogados en Netflix, series de abogados en Netflix, películas de Derecho"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0074-ART-APRENDE-CULTURA-GENERAL-180924.vue')
  },
  {
    path: '/blog/aprende/cultura-general/en-que-paises-se-habla-el-idioma-ingles',
    name: '¿En qué países se habla el idioma inglés? Guía completa, entérate',
    meta: {
      title: "¿En qué países se habla el idioma inglés? Guía completa, entérate",
      keywords: "en qué países se habla el idioma inglés, paises que hablan ingles, idioma inglés, qué idioma hablan en Inglaterra, 10 países que hablan inglés, donde se habla inglés, donde se habla ingles",
      metaTags:
      {
        "og:keywords": "en qué países se habla el idioma inglés, paises que hablan ingles, idioma inglés, qué idioma hablan en Inglaterra, 10 países que hablan inglés, donde se habla inglés, donde se habla ingles"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0056-ART-APRENDE-CULTURA-GENERAL-070724.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dia-mundial-redes-sociales-30-junio-2024',
    name: 'Día Mundial de las Redes Sociales: Celebrando el 30 de junio',
    meta: {
      title: "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
      description: "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
      keywords: "qué se celebra el 30 de junio, día mundial de las redes sociales, 30 de junio día internacional de las redes sociales",
      metaTags:
      {
        "og:title": "Día Mundial de las Redes Sociales: Celebrando el 30 de junio",
        "og:description": "Día Mundial de las Redes Sociales: conoce por qué se celebra y cómo las redes sociales influyen en nuestras vidas.",
        "og:keywords": "qué se celebra el 30 de junio, día mundial de las redes sociales, 30 de junio día internacional de las redes sociales"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0051-ART-APRENDE-CULTURA-GENERAL-270624.vue')
  },
  {
    path: '/blog/aprende/cultura-general/cuantos-pisos-tiene-la-torre-de-pemex',
    name: '¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?',
    meta: {
      title: "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
      description: "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
      keywords: "Cuántos pisos tiene la torre de Pemex, edificio más alto de México, edificio más alto de cdmx, torre pemex: Roberto Ramírez Guevara, torre más alta de méxico, edificios cdmx",
      metaTags:
      {
        "og:title": "¿Cuántos pisos tiene la torre de Pemex y cuál es su historia?",
        "og:description": "Conoce cuántos pisos tiene la torre de Pemex y cuál es la historia de este edificio, que es símbolo de la arquitectura moderna mexicana.",
        "og:keywords": "Cuántos pisos tiene la torre de Pemex, edificio más alto de México, edificio más alto de cdmx, torre pemex: Roberto Ramírez Guevara, torre más alta de méxico, edificios cdmx"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0044-ART-APRENDE-CULTURA-GENERAL-130624.vue')
  },
  {
    path: '/blog/aprende/cultura-general/consejos-sobre-uso-responsable-de-redes-sociales',
    name: 'Uso responsable de las redes sociales: Guía para navegar de forma segura',
    meta: {
      title: "Uso responsable de las redes sociales: Guía para navegar de forma segura",
      description: "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
      keywords: "Uso responsable de las redes sociales, Recomendaciones para el uso de las redes sociales, uso adecuado de las redes sociales, uso de las redes sociales, buen uso de las redes sociales, como usar correctamente las redes sociales",
      metaTags:
      {
        "og:title": "Uso responsable de las redes sociales: Guía para navegar de forma segura",
        "og:description": "El uso responsable de las redes sociales recae en el nivel de consciencia y educación de los usuarios respecto a los efectos y beneficios de estas plataformas.",
        "og:keywords": "Uso responsable de las redes sociales, Recomendaciones para el uso de las redes sociales, uso adecuado de las redes sociales, uso de las redes sociales, buen uso de las redes sociales, como usar correctamente las redes sociales"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0029-ART-APRENDE-CULTURA-GENERAL-210524.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dia-del-maestro-2024',
    name: 'Celebrando el Día del Maestro: reconozcamos a nuestros profesores',
    meta: {
      title: "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
      description: "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
      keywords: "",
      metaTags:
      {
        "og:title": "Celebrando el Día del Maestro: reconozcamos a nuestros profesores",
        "og:description": "La importancia del Día del Maestro: cómo se celebra en México y la influencia de los profesores en la sociedad. ¡Descubre más!",
        "og:keywords": ""
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/0025-ART-APRENDE-LICENCIATURA-CULTURA-GRAL-140524.vue')
  },
  {
    path: '/blog/aprende/cultura-general/dia-de-la-mujer-financiar-derechos-acelerar-igualdad-de-genero-2024',
    name: 'Día de la Mujer 2024: invertir para tener igualdad de género',
    meta: {
      title: "Día de la Mujer 2024: invertir para tener igualdad de género",
      description: "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
      keywords: "día mundial de la mujer, día internacional de la mujer, 8 de marzo, igualdad de género, financiar, invertir, acelerar",
      metaTags:
      {
        "og:title": "Día de la Mujer 2024: invertir para tener igualdad de género",
        "og:description": "Día Internacional de la Mujer: por qué es importante y cómo en 2024, financiar los derechos de las mujeres puede acelerar la igualdad de género.",
        "og:keywords": "día mundial de la mujer, día internacional de la mujer, 8 de marzo, igualdad de género, financiar, invertir, acelerar"
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/CulturGen/11-ART-APRENDE-CULTURA-GENERAL-080324.vue')
  },
]