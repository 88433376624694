export default [
    {
      path: '/blog/recursos/casos-reales/como-identificar-y-superar-relaciones-toxicas',
      name: 'Cómo identificar y superar relaciones tóxicas: Lo que debes de saber',
      meta: {
        title: "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
        description: "Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.",
        metaTags:
        {
          "og:title": "Cómo identificar y superar relaciones tóxicas: Lo que debes de saber",
          "og:description": "Aprende a reconocer los signos de una relación tóxica y cómo prevenirlos para vivir una vida más sana y feliz.",
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/Recursos/CasosReales/0053-ART-RECURSOS-CASOS-REALES-020724.vue')
    },
    {
        path: '/blog/recursos/casos-reales/perfil-criminologico-como-descifra-la-mente-criminal',
        name: 'Perfil criminológico: cómo los expertos descifran la mente criminal',
        meta: {
          title: "Perfil criminológico: cómo los expertos descifran la mente criminal",
          description: "El perfil criminológico permite internarse en la mente de los delincuentes para resolver casos complejos. Explora qué es.",
          keywords: "perfil criminológico, criminología, criminalística, diferencia criminología, psicología, perfilación criminal",
          metaTags:
          {
            "og:title": "Perfil criminológico: cómo los expertos descifran la mente criminal.",
            "og:description": "El perfil criminológico permite internarse en la mente de los delincuentes para resolver casos complejos. Explora qué es.",
            "og:keywords": "perfil criminológico, criminología, criminalística, diferencia criminología, psicología, perfilación criminal"
          },
          requireAuth: false,
        },
        component: () => import('../../pages/Blog/Recursos/CasosReales/17-ART-RECURSOS-CASOSREALES-090424.vue')
    },
]