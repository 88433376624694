//import RecursosEbooks from "../pages/Blog/Recursos/Recursos-Ebooks.vue";
// import RecursosPotcast from "../pages/Blog/Recursos/Recursos-Potcast.vue";
// import RecursosWebinars from "../pages/Blog/Recursos/Recursos-Webinars.vue";
import webinarsRoutes from "./Recursos/webinarsRoutes.ts"
import podcastRoutes from "./Recursos/podcastRoutes.ts";
import entrevistasRoutes from "./Recursos/entrevistasRoutes.ts";
import ebooksRoutes from "./Recursos/ebooksRoutes.ts";
import casosRRoutes from "./Recursos/casosRRoutes.ts"

export default [
    {
        path: '/blog/recursos/casos-reales',
        name: 'Recursos: Casos Reales | Blog Humanitas',
        component: () => import('../pages/Blog/Recursos/Recursos-CasosR.vue'),
        meta: {
            title: "Recursos: Casos Reales | Blog Humanitas",
            requireAuth: false
        },
    },
    ...casosRRoutes,
    // {
    //     path: '/blog/recursos/ebooks',
    //     name: 'Recursos: Ebooks | Blog Humanitas',
    //     component: () => import('../pages/Blog/Recursos/Recursos-Ebooks.vue'),
    //     meta: {
    //         title: "Blog Humanitas | Conocimiento que expande tu mente",
    //         requireAuth: false
    //     },
    // },
    ...ebooksRoutes,
    {
        path: '/blog/recursos/entrevistas-y-conferencias',
        name: 'Recursos: Entrevistas y Conferencias | Blog Humanitas',
        component: () => import('../pages/Blog/Recursos/Recursos-EntrevistasC.vue'),
        meta: {
            title: "Recursos: Entrevistas y Conferencias | Blog Humanitas",
            requireAuth: false
        },
    },
    ...entrevistasRoutes,
    // {
    //     path: '/blog/recursos/podcast',
    //     name: 'Recursos: Podcast | Blog Humanitas',
    //     component: RecursosPotcast,
    //     meta: {
    //         title: "Blog Humanitas | Conocimiento que expande tu mente",
    //         requireAuth: false
    //     },
    // },
    ...podcastRoutes,
    // {
    //     path: '/blog/recursos/webinars',
    //     name: 'Recursos: Webinars',
    //     component: RecursosWebinars,
    //     meta: {
    //         title: "Blog Humanitas | Conocimiento que expande tu mente",
    //         requireAuth: false
    //     },
    // },
    ...webinarsRoutes,
]