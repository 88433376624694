import AcademicoRoutes from "./AcademicoRoutes.ts";
import AprendeRoutes from "./AprendeRoutes.ts";
import RecursosRoutes from "./RecursosRoutes.ts";
import SerHumRoutes from "./SerHumRoutes.ts";
import Hist2024 from "./Historico/Hist2024.ts";

export default 
    [
        {
            path: '/blog',
            name: 'Blog Humanitas',
            component: () => import('../pages/Blog/Home.vue'),
            meta: {
                title: "Blog Humanitas | Conocimiento que expande tu mente",
                requireAuth: false,
            }
        },
        ...Hist2024,
        {
            path: '/blog/academico',
            name: 'Académico | Blog Humanitas',
            component: () => import('../pages/Blog/Academico.vue'),
            meta: {
                title: "Académico | Blog Humanitas",
				requireAuth: false
			},
        },
        ...AcademicoRoutes,
        {
            path: '/blog/aprende',
            name: 'Aprende | Blog Humanitas',
            component: () => import('../pages/Blog/Aprende.vue'),
            meta: {
                title: "Aprende | Blog Humanitas",
				requireAuth: false
			},
        },
        ...AprendeRoutes,
        {
            path: '/blog/recursos',
            name: 'Recursos | Blog Humanitas',
            component: () => import('../pages/Blog/Recursos.vue'),
            meta: {
                title: "Recursos | Blog Humanitas",
				requireAuth: false
			},
        },
        ...RecursosRoutes,
        {
            path: '/blog/ser-humanitas',
            name: 'Ser Humanitas | Blog Humanitas',
            component: () => import('../pages/Blog/SerHum.vue'),
            meta: {
                title: "Ser Humanitas | Blog Humanitas",
				requireAuth: false
			},
        },
        ...SerHumRoutes,
        
    ]

