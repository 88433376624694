export default [
  {
    path: '/blog/aprende/tips/que-es-la-impuntualidad',
    name: '¿Qué es la impuntualidad y qué dice de tu personalidad?',
    meta: {
      title: "¿Qué es la impuntualidad y qué dice de tu personalidad?",
      keywords: "Qué es la impuntualidad, causas de la impuntualidad en el trabajo, impuntualidad significado, consecuencias de la puntualidad, consecuencias de ser impuntual",
      metaTags:
      {
        "og:keywords": "Qué es la impuntualidad, causas de la impuntualidad en el trabajo, impuntualidad significado, consecuencias de la puntualidad, consecuencias de ser impuntual",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0095-ART-APRENDE-TIPS-251024.vue')
  },
  {
    path: '/blog/aprende/tips/como-ser-feliz-con-uno-mismo',
    name: '¿Cómo ser feliz con uno mismo?: revisa estos consejos',
    meta: {
      title: "¿Cómo ser feliz con uno mismo?: revisa estos consejos",
      keywords: "como ser feliz en la vida, ser feliz, como ser feliz solo, que se necesita para ser feliz, aprende a ser feliz en soledad, Consejos",
      metaTags:
      {
        "og:title": "¿Cómo ser una persona exitosa?",
        "og:keywords": "como ser feliz en la vida, ser feliz, como ser feliz solo, que se necesita para ser feliz, aprende a ser feliz en soledad, Consejos",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0090-ART-APRENDE-TIPS-151024.vue')
  },
  {
    path: '/blog/aprende/tips/como-ser-una-persona-exitosa',
    name: '¿Cómo ser una persona exitosa?',
    meta: {
      title: "¿Cómo ser una persona exitosa?",
      description: "No existe una respuesta única para la pregunta: ¿Cómo ser una persona exitosa?, pero sí hay varios caminos para entrar en un estado de plenitud y de bienestar.",
      keywords: "Cómo ser una persona exitosa, gente exitosa, que es ser exitoso, Hábitos para ser mejor persona, Hábitos de éxito",
      metaTags:
      {
        "og:title": "¿Cómo ser una persona exitosa?",
        "og:description": "No existe una respuesta única para la pregunta: ¿Cómo ser una persona exitosa?, pero sí hay varios caminos para entrar en un estado de plenitud y de bienestar.",
        "og:keywords": "Cómo ser una persona exitosa, gente exitosa, que es ser exitoso, Hábitos para ser mejor persona, Hábitos de éxito",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0088-ART-APRENDE-TIPS-111024.vue')
  },
  {
    path: '/blog/aprende/tips/que-hacer-si-no-te-quedaste-en-el-ipn',
    name: '¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta',
    meta: {
      title: "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
      description: "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
      keywords: "¿Qué hacer si no te quedaste en el IPN?, Opciones educativas privadas, Opciones educativas públicas, ¿Cuáles son las mejores universidades privadas?",
      metaTags:
      {
        "og:title": "¿Qué hacer si no te quedaste en el IPN?, aquí la respuesta",
        "og:description": "¿Qué hacer si no te quedaste en el IPN? Descubre las mejores opciones educativas públicas y privadas de educación superior.",
        "og:keywords": "¿Qué hacer si no te quedaste en el IPN?, Opciones educativas privadas, Opciones educativas públicas, ¿Cuáles son las mejores universidades privadas?",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0058-ART-APRENDE-TIPS-010824.vue')
  },
  {
    path: '/blog/aprende/tips/no-te-quedaste-en-la-unam-descubre-estas-alternativas',
    name: '¿No te quedaste en la UNAM? Descubre estas alternativas',
    meta: {
      title: "¿No te quedaste en la UNAM? Descubre estas alternativas",
      description: "Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
      keywords: "que hacer si no te quedaste en la unam, que hacer si no te quedaste en la UNAM, opciones educativas públicas, opciones educativas privadas, que hago si no me quedé en la UNAM, alternativas universitarias",
      metaTags:
      {
        "og:title": "¿No te quedaste en la UNAM? Descubre estas alternativas",
        "og:description": "Si presentaste tu examen de admisión a la UNAM y no fuiste seleccionado, te damos alternativas para continuar tu educación universitaria. ¡Conócelas!",
        "og:keywords": "que hacer si no te quedaste en la unam, que hacer si no te quedaste en la UNAM, opciones educativas públicas, opciones educativas privadas, que hago si no me quedé en la UNAM, alternativas universitarias",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0057-ART-APRENDE-TIPS-310724.vue')
  },
  {
    path: '/blog/aprende/tips/ansiedad-es-lo-mismo-que-angustia',
    name: '¿Ansiedad es lo mismo que angustia?: Aquí la respuesta',
    meta: {
      title: "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
      description: "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
      keywords: "ataque de ansiedad, cómo evitar la angustia y el miedo, diferencia entre estrés y ansiedad, la ansiedad es una emoción o un sentimiento, diferencia entre angustia y ansiedad",
      metaTags:
      {
        "og:title": "¿Ansiedad es lo mismo que angustia?: Aquí la respuesta",
        "og:description": "Alguna vez te haz preguntado: ¿Ansiedad es lo mismo que angustia?, ¿Haz experimentado estos padecimientos?, te decimos cómo evitarlos.",
        "og:keywords": "ataque de ansiedad, cómo evitar la angustia y el miedo, diferencia entre estrés y ansiedad, la ansiedad es una emoción o un sentimiento, diferencia entre angustia y ansiedad",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0055-ART-APRENDE-TIPS-050724.vue')
  },
  {
    path: '/blog/aprende/tips/puntos-clave-para-evaluar-la-calidad-de-una-universidad-privada',
    name: '¿Cómo evaluar la calidad de una universidad privada?',
    meta: {
      title: "¿Cómo evaluar la calidad de una universidad privada?",
      description: "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
      keywords: "¿Cómo evaluar la calidad de una universidad privada?,Criterios para evaluar mejores universidades, profesores y docentes, evaluación institucional.",
      metaTags:
      {
        "og:title": "¿Cómo evaluar la calidad de una universidad privada?",
        "og:description": "Conoce los puntos clave para elegir la mejor opción educativa y responde a la pregunta: ¿Cómo evaluar la calidad de una universidad privada?",
        "og:keywords": "¿Cómo evaluar la calidad de una universidad privada?,Criterios para evaluar mejores universidades, profesores y docentes, evaluación institucional.",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/0033-ART-APRENDE-TIPS-280524.vue')
  },
  {
    path: '/blog/aprende/tips/mejores-tecnicas-estudio-para-universidad',
    name: 'Domina tus estudios: técnicas y consejos para tu éxito universitario',
    meta: {
      title: "Domina tus estudios: técnicas y consejos para tu éxito universitario",
      description: "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
      metaTags:
      {
        "og:title": "Domina tus estudios: técnicas y consejos para tu éxito universitario",
        "og:description": "Descubre las mejores técnicas de estudio y consejos para maximizar tu rendimiento en la universidad. Estudia de manera más inteligente y eficaz.",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/03-ART-APRENDE-TIPS-240124.vue')
  },
  {
    path: '/blog/aprende/tips/estoicismo-para-estudiantes-universitarios-y-beneficios',
    name: 'Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos',
    meta: {
      title: "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
      description: "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
      metaTags:
      {
        "og:title": "Estoicismo: cómo puede ayudar a los estudiantes a superar desafíos",
        "og:description": "Explora los principios del Estoicismo y cuáles son sus beneficios en tu vida universitaria.",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tips/13-ART-APRENDE-TIPS-150324.vue')
  },
  {
      path: '/blog/aprende/tips/maximiza-tu-productividad-estilo-bill-gates',
      name: 'Maximiza tu productividad al estilo de Bill Gates',
      meta: {
        title: "Maximiza tu productividad al estilo de Bill Gates",
        description: "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
        metaTags:
        {
          "og:title": "Maximiza tu productividad al estilo de Bill Gates",
          "og:description": "Descubre la técnica de Bill Gates y potencia tu productividad, ¡lee más!",
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/Aprende/Tips/16-ART-APRENDE-TIPS-020424.vue')
  },
]