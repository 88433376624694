export default [
  {
    path: '/blog/aprende/tendencias/elecciones-estados-unidos-2024-emocionalidad-y-marketing-politico',
    name: 'Elecciones en EE. UU.  2024: Emocionalidad y lecciones de marketing político',
    meta: {
      title: "Elecciones en EE. UU.  2024: Emocionalidad y lecciones de marketing político",
      requireAuth: false,
      keywords: "elecciones estados unidos, elecciones usa, Donald Trump, Kamala Harris, marketing politico, marketing político, emocionalidad, celebridades y elecciones, influencia, elecciones, Taylor Swift, Elon Musk, lecciones, viralidad, storytelling",
      metaTags:
      {
        "og:keywords": "elecciones estados unidos, elecciones usa, Donald Trump, Kamala Harris, marketing politico, marketing político, emocionalidad, celebridades y elecciones, influencia, elecciones, Taylor Swift, Elon Musk, lecciones, viralidad, storytelling"
      },
    },
    component: () => import('../../pages/Blog/Aprende/Tendencias/0099-ART-APRENDE-TENDENCIAS-041124.vue')
  },
  {
    path: '/blog/aprende/tendencias/la-sustancia-analisis-psicologico-de-la-pelicula',
    name: 'La Sustancia: ¿Hasta dónde llegarías para ser siempre joven?',
    meta: {
      title: "La Sustancia: ¿Hasta dónde llegarías para ser siempre joven?",
      requireAuth: false,
      keywords: "análisis psicológico de película, temor a la vejez, juventud, procedimientos esteticos, body horror, horror corporal, cinematrográficos",
      metaTags:
      {
        "og:keywords": "análisis psicológico de película, temor a la vejez, juventud, procedimientos esteticos, body horror, horror corporal, cinematrográficos"
      },
    },
    component: () => import('../../pages/Blog/Aprende/Tendencias/0091-ART-APRENDE-TENDENCIAS-171024.vue')
  },
  {
      path: '/blog/aprende/tendencias/diez-apps-inteligencia-artificial-populares-2024',
      name: 'Diez apps de inteligencia artificial más populares: Conócelas',
      meta: {
        title: "Diez apps de inteligencia artificial más populares: Conócelas",
        requireAuth: false,
        keywords: "apps inteligencia artificial, herramientas de inteligencia artificial, programas de inteligencia artificial, app de inteligencia artificial, apps con inteligencia artificial, herramientas de ia, inteligencia artificial populares",
        metaTags:
        {
          "og:keywords": "apps inteligencia artificial, herramientas de inteligencia artificial, programas de inteligencia artificial, app de inteligencia artificial, apps con inteligencia artificial, herramientas de ia, inteligencia artificial populares"
        },
      },
      component: () => import('../../pages/Blog/Aprende/Tendencias/0076-ART-APRENDE-TENDENCIAS-270924.vue')
  },
  {
      path: '/blog/aprende/tendencias/la-casa-de-los-famosos-mexico-2-analisis',
      name: 'La Casa De Los Famosos México 2: más allá del rating',
      meta: {
        title: "La Casa De Los Famosos México 2: más allá del rating",
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/Aprende/Tendencias/0072-ART-APRENDE-TENDENCIAS-130924.vue')
  },
  {
      path: '/blog/aprende/tendencias/origen-evolucion-reality-shows-sociologia-comunicacion-lcdelfm2',
      name: 'Origen y evolución de los reality shows: una mirada sociológica y de comunicación',
      meta: {
        title: "Origen y evolución de los reality shows: una mirada sociológica y de comunicación",
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/Aprende/Tendencias/0073-ART-APRENDE-TENDENCIAS-130924.vue')
  },
  {
      path: '/blog/aprende/tendencias/tendencias-administracion-empresas-2024',
      name: 'Estas son las tendencias clave en la administración en 2024, descúbrelas.',
      meta: {
        title: "Estas son las tendencias clave en la administración en 2024, descúbrelas.",
        description: "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
        image: "https://blog.humanitas.edu.mx/images/meta/Tendencias-administracion-empresarial-2024-multinube.jpg",
        metaTags:
        {
          "og:title": "Estas son las tendencias clave en la administración en 2024, descúbrelas.",
          "og:description": "Entérate de las tendencias más relevantes que dan forma al futuro de la administración de empresas en 2024.",
          "og:image": "https://blog.humanitas.edu.mx/images/meta/Tendencias-administracion-empresarial-2024-multinube.jpg",
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/Aprende/Tendencias/04-ART-APRENDE-TENDENCIAS-310124.vue')
  },
  {
      path: '/blog/aprende/tendencias/tendencias-actuales-del-derecho-2024',
      name: 'Infórmate acerca de las tendencias actuales del Derecho en 2024.',
      meta: {
        title: "Infórmate acerca de las tendencias actuales del Derecho en 2024.",
        description: "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
        metaTags:
        {
          "og:title": "Infórmate acerca de las tendencias actuales del Derecho en 2024.",
          "og:description": "Entérate cuáles son las principales tendencias actuales del Derecho en 2024. Mantente al día con los cambios e innovaciones que conforman el futuro legal.",
        },
        requireAuth: false,
      },
      component: () => import('../../pages/Blog/Aprende/Tendencias/07-ART-APRENDE-TENDENCIAS-190224.vue')
  },
  {
    path: '/blog/aprende/tendencias/tendencias-en-psicologia-2024-tecnologia-salud-mental',
    name: 'Tendencias en Psicología para 2024: Tecnología en la salud mental',
    meta: {
      title: "Tendencias en Psicología para 2024: Tecnología en la salud mental",
      description: "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
      metaTags:
      {
        "og:title": "Tendencias en Psicología para 2024: Tecnología en la salud mental",
        "og:description": "Conoce las tendencias clave en Psicología en 2024, la salud mental y las aplicaciones digitales. Averigua cómo la tecnología puede ayudar al bienestar.",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tendencias/15-ART-APRENDE-TENDENCIAS-270324.vue')
  },
  {
    path: '/blog/aprende/tendencias/competencias-digitales-para-profesionales',
    name: 'Mejores competencias digitales para profesionales',
    meta: {
      title: "Mejores competencias digitales para profesionales",
      description: "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
      keywords: "",
      metaTags:
      {
        "og:title": "Mejores competencias digitales para profesionales",
        "og:description": "Conoce cuáles son las competencias digitales para profesionales más relevantes que debes desarrollar para ser un profesional.",
        "og:keywords": "",
      },
      requireAuth: false,
    },
    component: () => import('../../pages/Blog/Aprende/Tendencias/0028-ART-SER-HUMANITAS-APRENDE-TENDENCIAS-160524.vue')
  },
]