//import SerHumActCamp from "../pages/Blog/SerHuma/SerHum-ActCamp.vue";
// import SerHumCE from "../pages/Blog/SerHuma/SerHum-CE.vue";
// import SerHumTesti from "../pages/Blog/SerHuma/SerHum-Testi.vue";
import actCampRoutes from "./SerHum/actCampRoutes.ts";
import casosERoutes from "./SerHum/casosERoutes.ts";
import respSocRoutes from "./SerHum/respSocRoutes.ts";
import testimoniosRoutes from "./SerHum/testimoniosRoutes.ts";
import comunidadRoutes from "./SerHum/comunidadRoutes.ts"

export default [
    // {
    //     path: '/blog/ser-humanitas/actividades-en-campus',
    //     name: 'Ser Humanitas: Actividades en Campus | Blog Humanitas',
    //     component: SerHumActCamp,
    //     meta: {
    //         title: "Ser Humanitas: Actividades en Campus | Blog Humanitas",
    //         requireAuth: false
    //     },
    // },
    ...actCampRoutes,
    // {
    //     path: '/blog/ser-humanitas/casos-de-exito',
    //     name: 'Ser Humanitas: Casos de Éxito | Blog Humanitas',
    //     component: SerHumCE,
    //     meta: {
    //         title: "Ser Humanitas: Casos de Éxito | Blog Humanitas",
    //         requireAuth: false
    //     },
    // },
    ...casosERoutes,
    {
        path: '/blog/ser-humanitas/responsabilidad-social',
        name: 'Ser Humanitas: Responsabilidad Social | Blog Humanitas',
        component: () => import('../pages/Blog/SerHuma/SerHum-RespoS.vue'),
        meta: {
            title: "Ser Humanitas: Responsabilidad Social | Blog Humanitas",
            requireAuth: false
        },
    },
    ...respSocRoutes,
    // {
    //     path: '/blog/ser-humanitas/testimonios',
    //     name: 'Ser Humanitas: Testimonios | Blog Humanitas',
    //     component: SerHumTesti,
    //     meta: {
    //         title: "Ser Humanitas: Testimonios | Blog Humanitas",
    //         requireAuth: false
    //     },
    // },
    ...testimoniosRoutes,
    {
        path: '/blog/ser-humanitas/comunidad',
        name: 'Ser Humanitas: Comunidad | Blog Humanitas',
        component: () => import('../pages/Blog/SerHuma/SerHum-Comunidad.vue'),
        meta: {
            title: "Ser Humanitas: Comunidad | Blog Humanitas",
            requireAuth: false
        },
    },
    ...comunidadRoutes,
]