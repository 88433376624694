import cultuGenRoutes from "./Aprende/cultuGenRoutes.ts"
import tipsRoutes from "./Aprende/tipsRoutes.ts";
import tendenciasRoutes from "./Aprende/tendenciasRoutes.ts";

export default [
    {
        path: '/blog/aprende/cultura-general',
        name: 'Aprende: Cultura General | Blog Humanitas',
        component: () => import('../pages/Blog/Aprende/Aprende-CulturaG.vue'),
        meta: {
            title: "Aprende: Cultura General | Blog Humanitas",
            requireAuth: false
        },
    },
    ...cultuGenRoutes,
    {
        path: '/blog/aprende/tendencias',
        name: 'Aprende: Tendencias | Blog Humanitas',
        component: () => import('../pages/Blog/Aprende/Aprende-Tendencias.vue'),
        meta: {
            title: "Aprende: Tendencias | Blog Humanitas",
            requireAuth: false
        },
    },
    ...tipsRoutes,
    {
        path: '/blog/aprende/tips',
        name: 'Aprende: Tips | Blog Humanitas',
        component: () => import('../pages/Blog/Aprende/Aprende-Tips.vue'),
        meta: {
            title: "Aprende: Tips | Blog Humanitas",
            requireAuth: false
        },
    },
    ...tendenciasRoutes,
]